<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Using standard reduction potentials
        <a
          href="https://openstax.org/books/chemistry-2e/pages/l-standard-electrode-half-cell-potentials"
          target="_blank"
          rel="noopener noreferrer"
        >
          available here,</a
        >
        answer the questions below:
      </p>

      <p class="mb-5 pl-14">
        <chemical-latex :content="reaction" />
      </p>

      <p class="mb-2">a) What is the standard cell potential (or emf) for this cell?</p>

      <calculation-input
        v-model="inputs.Ecell"
        class="mb-5"
        prepend-text="$\text{E}^\circ_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is the standard free energy change
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        for the process?
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-5"
        prepend-text="$\Delta\text{G}^\circ:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">c) Is this process spontaneous in the forward direction?</p>

      <v-radio-group v-model="inputs.spontaneity" class="pl-8 mb-n5" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question426',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ecell: null,
        deltaG: null,
        spontaneity: null,
      },
      options: [
        {expression: 'Spontaneous', value: 'spontaneous'},
        {expression: 'Non-spontaneous', value: 'non'},
        {expression: 'Insufficient information', value: 'unknown'},
      ],
    };
  },
  computed: {
    reactionNumber() {
      return this.taskState.getValueBySymbol('reactionNumber').content;
    },
    reaction() {
      if (this.reactionNumber.value === 1) {
        return '2Cl-(aq) + I2(s) -> Cl2(g) + 2I-(aq)';
      } else if (this.reactionNumber.value === 2) {
        return 'Hg^2+(aq) + 2I^-(aq) -> Hg(l) + I2(s)';
      } else if (this.reactionNumber.value === 3) {
        return 'Cu^2+(aq) + H2(g) -> Cu(s) + 2H^+(aq)';
      } else {
        return 'Mg(s) + Ni^2+(aq) -> Mg^2+(aq) + Ni(s)';
      }
    },
  },
};
</script>
